*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App {
  text-align: center;

font-family: 'Rubik', sans-serif;
  
}
.footer {
  cursor: pointer;
  
}
.footer:hover{
  font-style: italic;
}
.insta {


  border-radius: 30px;
  color: #fff;
  
  background: #d6249f;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
 
}
.div {
 aspect-ratio: 22/9;
   background-image: linear-gradient(rgba(165, 55, 253), rgba(255, 255, 0, 0.5)),
   url("../src/lost.jpg");
}
.div2 {
  clip-path: polygon(0 0, 0% 100%, 100% 0);;
  background-color: #f7d474;
  
}


